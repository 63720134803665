.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	outline: 0;
}

.modal_dialog {
	display: flex;
	align-items: center;
	min-height: calc(100% - 3.5rem);
	position: relative;
	width: auto;
	pointer-events: none;
	margin: 0.5rem;
	@media (min-width: 576px) {
		max-width: 500px;
		margin: 1.75rem auto;
	}
}

.modal_content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
	border: none;
	outline: none;
	padding: 3rem 2rem;
	margin: 2rem 0;
}

.modal-header {
	display: 'flex';
	align-items: 'center';
	justify-content: 'space-between';

	h2 {
		font-weight: normal;
	}
}

.modal-body {
	margin: 2.5rem 0;
}

.modal-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 2rem;
}
