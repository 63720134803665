@import 'scss/variables.scss';

.Dashboard {
}

.Dashboard_Content {
	margin: 1rem 0;
	padding: 4rem;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	width: 100%;
	min-height: 420px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
	}
}

.Dashboard_Card {
	display: flex;
	flex: 0.47;
	flex-direction: column;
	position: relative;

	@media screen and (max-width: 1000px) {
		&:nth-child(2) {
			margin-top: 3rem;
		}
	}
}

.Dashboard_CardTitle {
	font-size: 14px;
	letter-spacing: 0.5px;
	color: $base-color;
	text-transform: uppercase;
}

.Dashboard_Title {
	margin-left: 3.9rem;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	color: $base-color;
	display: flex;
	align-items: flex-start;

	h2 {
		font-size: 2rem;
		font-weight: 500;

		@media screen and (max-width: 450px) {
			font-size: 1.8rem;
		}
	}

	span {
		margin-left: 1.2rem;
		font-size: 1.2rem;
		margin-top: 1px;
		font-weight: 700;

		@media screen and (max-width: 450px) {
			font-size: 1.1rem;
		}
	}
}

.Dashboard_BG1 {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: #e6edec;
	z-index: -1;

	@media screen and (max-width: 768px) {
		display: none;
	}
}
