@import './variables.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: $base-font-size;
}

body {
	background: $bg-color;
	margin: 0;
	font-size: 1.6rem;
	font-family: -apple-system, BlinkMacSystemFont, $font, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a,
li,
button,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea,
input[type='text'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
	outline: none !important;
	border-radius: 0 !important;
	box-shadow: none !important;
}
