@import 'scss/variables.scss';

.MenuButton {
	cursor: pointer;
	position: relative;
	display: flex;
	height: 11px;
	width: 20px;
	flex-direction: column;

	&:after {
		transition: 0.2s all ease-in;
		content: '';
		position: absolute;
		background-color: transparent;
		opacity: 0.1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 38px;
		height: 38px;
		border: 1px solid transparent;
		border-radius: 20px;
	}

	span {
		width: 20px;
		height: 2px;
		background: $base-color;

		&:nth-child(2) {
			transform: translateY(3px);
		}

		&:nth-child(3) {
			transform: translateY(6px);
		}
	}

	&:hover {
		&:after {
			background-color: $base-color;
		}
	}
}
