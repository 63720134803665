.checker {
	display: block;
	font-size: 30px;
	width: 2.5em;
	box-sizing: content-box;
	padding: 0.15em;
	border-radius: 0.2em;
	transition: transform 0.4s ease;
	cursor: pointer;
}

.checkmark {
	width: 0.8em;
	height: 0.8em;
	transition: transform 0.4s ease;
	transform: translate(-0.4em, 0.5px);
	z-index: 5;

	svg {
		display: block;
		background: #e5e5e5;
		transform: translateX(0.4em);
		border-radius: 0.15em;
		transition: background-color 0.4s ease, transform calc(0.4s * 1.5) ease;

		path {
			// opacity: 0;
			stroke-dasharray: 90 90;
			stroke-dashoffset: 90;
			transition: stroke-dashoffset calc(0.4s / 3) linear calc(0.4s / 3);
		}
	}
}

.checkbox {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.4;
	visibility: hidden;

	&:checked {
		~ .checkmark {
			transform: translate(2.1em, 0.5px);

			svg {
				background: $green;
				transform: translate(-0.4em);
				path {
					stroke-dashoffset: 0;
				}
			}
		}
	}
}

.check-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background: transparent;
	z-index: 0;
	border: solid 1px #ebebeb;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: inherit;
	}

	&:after {
		background: white;
	}

	&:before {
		transition: transform 0.4s ease;
		transform: translateY(10%);
		transform-origin: bottom center;
		background: $black-lighter;
		filter: blur(0.1em);
		opacity: 0.1;
		z-index: -1;
	}
}

.checker.checker {
	&:active {
		transform: scale(0.85);
		transition-duration: calc(0.4s / 2);

		.check-bg::before {
			transform: translateY(0) scale(0.8);
			opacity: 0.2;
		}
	}
}
