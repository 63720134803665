.tooltip {
	font-family: $font;
	color: $white !important;
	background-color: $base-color !important;
	max-width: 300px;
	//padding: 10px;
	letter-spacing: 0.3px;
	line-height: 2rem;
	border-radius: 0 !important;
	font-size: 14px;
	opacity: 0.9 !important;
	border: 1px solid $base-color !important;
	// box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 5px 10px 0 #f1f3f5, 0 3px 3px 0 rgba(0, 0, 0, 0.15);
	&.place-top {
		&:after {
			border-top-color: $base-color !important;
			border-top-style: solid !important;
			border-top-width: 6px !important;
		}

		&:before {
			border-top-color: $base-color !important;
			border-top-style: solid !important;
			border-top-width: 8px !important;
		}
	}
}

.__react_component_tooltip {
	transition: opacity 0.25s ease-in-out !important;
	opacity: 0 !important;
	visibility: visible;
}

.__react_component_tooltip.show {
	visibility: visible;
	opacity: 0.9 !important;
}
