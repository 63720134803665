@import './variables.scss';

::-webkit-scrollbar {
	width: 1rem;
	cursor: pointer;
}

::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb {
	background-color: lighten($color: $black-lighter, $amount: 50);
	border-radius: 0px;
	cursor: pointer;
}
