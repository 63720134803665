@import 'scss/variables.scss';

.Drawer {
	height: 100%;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
	background-color: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	width: 300px;
	z-index: 200;
	transform: translateX(-110%);
	transition: transform 0.3s ease-out;

	@media screen and (max-width: 475px) {
		width: 250px;
	}
}

.open {
	transform: translateX(0);
}

.Drawer_Logo {
	height: 40px;
	width: 140px;
	margin-top: 2rem;
	margin-left: 3rem;
}

.Drawer_Nav {
	margin-top: 4rem;

	ul {
		display: inline-flex;
		width: 100%;
		flex-direction: column;
	}

	li {
		cursor: pointer;
		display: inline-flex;
		transition: 0.2s background-color;

		&:hover {
			background-color: $base-color;

			a,
			button {
				color: $white;
			}
		}

		a {
			width: 100%;
		}

		a,
		button {
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0.5px;
			color: $base-color;
			text-transform: uppercase;
			text-decoration: none;
			background-color: transparent;
			outline: none;
			border: none;
			padding: 1.5rem 0;
			padding-left: 4rem;
			display: block;

			&:focus {
				outline: none;
			}
		}
	}
}

.Drawer_Line {
	margin: 2rem 3rem 2rem 3rem;
	width: 70%;
	height: 1px;
	border: solid 1px #d4dbda;
}

.Drawer_Backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: transparent;
	z-index: 100;
}
