@import 'scss/variables.scss';

.ContactUs {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 4rem auto;

	p {
		color: $base-color !important;
		margin-bottom: 2rem;
		font-size: 1.7rem;
		font-weight: 500;
		letter-spacing: 0.5px;
		text-align: center;
	}

	button {
		color: $base-color !important;
		border-radius: 1px;
		text-transform: capitalize;
		font-weight: 500 !important;
		background: #e6edec;
		padding: 9px 20px;
		transition: all 0.2s ease-out;

		&:hover {
			background: #d7d9d9;
		}
	}
}
