@import 'scss/variables.scss';

.Header {
	height: $header-height;
	display: flex;
	width: 100%;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	align-items: center;
	background-color: $white;
}

.Header_Content {
	margin-right: auto;
	margin-left: auto;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (min-width: 1900px) {
		width: 100% !important;
	}
}

.Header_Logo {
	height: 40px;
	width: 140px;
}

.Header_Menu {
	margin-right: 0.9rem;

	@media screen and (min-width: 1900px) {
		margin-right: 3rem;
	}
}
