$base-color: #1c223b;
$white: #ffffff;
$black: #000000;
$black-lighter: #323232;
$btn-primary: #1ba5c3;
$green: #58a900;
$red: #df0803;
$header-height: 80px;
$grayBorder: #ebebeb;
$bg-color: #f8f8f8;

$font: 'Lato';
$base-font-size: 62.5%;
