@import url('https://fonts.googleapis.com/css?family=Lato:400,500,700&display=swap');
@import './variables.scss';
@import './normalize.scss';
@import './input.scss';
@import './tooltips.scss';
@import './modal.scss';
@import './spinner.scss';
@import './toggle.scss';
@import './scrollbar.scss';
@import './button.scss';
@import './alert.scss';

@media screen and (min-width: 1400px) {
	.main-content-wrapper {
		width: 1370px;
	}
}

@media screen and (min-width: 1600px) {
	.main-content-wrapper {
		width: 1570px;
	}
}

@media screen and (min-width: 1900px) {
	.main-content-wrapper {
		width: 1870px;
	}
}

.positive {
	color: $green !important;
}

.negative {
	color: $red !important;
}

.black {
	color: $black-lighter !important;
}
