@import './variables.scss';

.primary-btn {
	font-weight: 700 !important;
	color: $btn-primary !important;
	font-family: $font !important;
	font-size: 1.5rem !important;
}

.default-btn {
	font-weight: 700 !important;
	color: $black-lighter !important;
	font-family: $font !important;
	font-size: 1.5rem !important;
}
