.form-input {
	border: solid 1px #ececec;
	resize: none;
	padding: 0.8rem 1.5rem;
	font-size: 1.6rem;
	font-family: $font;
	line-height: 1.44;
	color: $black-lighter;
	line-height: 26px;
	transition: all 0.3s ease-in-out;
	width: 100%;
	box-shadow: none !important;

	&:focus {
		outline: none !important;
		border: 1px solid $black-lighter;
		box-shadow: none;
	}

	&::placeholder {
		color: $black-lighter;
		opacity: 0.3;
		font-size: 1.6rem;
		font-family: $font;
		line-height: 1.44;
	}
}

.MuiTextField-root {
	width: 100% !important;
}

.MuiOutlinedInput-root {
	border-radius: 2px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	transition: 0.3s all ease-in-out !important;
	border-color: $base-color !important;
}

.MuiOutlinedInput-notchedOutline {
	border: 1px solid #ececec !important;

	legend {
		span {
			padding-right: 25px !important;
		}
	}
}

.MuiTextField-root .MuiFormLabel-root {
	font-size: 1.6rem !important;
	color: $black-lighter !important;
	opacity: 0.3 !important;
	font-family: $font !important;
	line-height: 1.44 !important;
	transform: translate(14px, 11px) scale(1);
	background: white !important;
	z-index: 100 !important;
}

.MuiFormLabel-root.Mui-focused {
	color: $black-lighter !important;
	opacity: 1 !important;
	background: white !important;
	z-index: 100 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid $base-color !important;
}

.MuiOutlinedInput-input {
	padding: 13px !important;
}

.MuiInputBase-input {
	font-size: 1.6rem !important;
	font-family: $font !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -10px) scale(0.8) !important;
}

.MuiOutlinedInput-multiline {
	padding: 10px 0 !important;
}

.MuiOutlinedInput-inputMultiline {
	line-height: 20px !important;
}

.MuiFormLabel-root.Mui-error {
	color: $red !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: $red !important;
}

.form-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 2rem 0;

	.input-error {
		color: $red;
		margin-top: 1rem;
	}

	.MuiFormControlLabel-root {
		margin-right: 4px !important;
	}

	.form-checkbox {
		font-family: $font !important;
		display: flex;
		align-items: center;
		font-size: 16px !important;

		.MuiTypography-body1 {
			font-family: $font !important;
			font-size: 1.6rem !important;
		}

		.MuiCheckbox-root {
			color: $base-color;
		}

		svg {
			font-size: 25px !important;
		}

		a {
			font-family: $font !important;
			font-size: 1.6rem !important;
			color: $black-lighter !important;
			line-height: 1.5;
			letter-spacing: 0.00938em;
			font-weight: 400;
		}

		.input-error {
			margin-top: 0 !important;
		}

		@media screen and (max-width: 550px) {
			a {
				font-size: 1.4rem !important;
			}

			.MuiTypography-body1 {
				font-size: 1.4rem !important;
			}

			svg {
				font-size: 20px !important;
			}
		}
	}
}

.MuiSelect-select:focus {
	background-color: white !important;
}
