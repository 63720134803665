@import 'scss/variables.scss';

.Results {
	margin-top: 1rem;
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	@media screen and (max-width: 1899px) {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}
}

.Result {
	display: flex;
	flex-direction: column;
	padding: 2rem 1.5rem;
	border: solid 1px $grayBorder;
	background-color: $white;
	justify-content: space-between;

	p {
		margin-bottom: 2rem;
		font-size: 11px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}

	& > div {
		min-height: 4.1rem;
		display: flex;
		justify-content: space-between;

		span {
			font-size: 20px;
			color: #aaa;
			text-transform: capitalize;
		}
	}
}

.Result_Language {
	font-size: 14px !important;
}

.Result_Info {
	cursor: pointer;
	width: 20px;
	height: 20px;
	border: 2px solid $btn-primary;
	border-radius: 50%;
	position: relative;
	color: $btn-primary;
	transform: translateY(2px);

	svg {
		width: 4.3px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.Result_Value {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	span {
		&:nth-child(2) {
			font-size: 1.4rem;
		}
	}
}
