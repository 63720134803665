@import 'scss/variables.scss';

.Search_Textarea {
	position: relative;

	button {
		position: absolute;
		right: 2.5rem;
		top: 3.2rem;
		outline: none;
		background: transparent;
		border: none;
		cursor: pointer;
		color: $black-lighter;
		font-size: 1.2rem;

		@media screen and (max-width: 550px) {
			right: 1.8rem;
			top: 3.7rem;
		}

		&:after {
			transition: 0.2s all ease-in;
			content: '';
			position: absolute;
			background-color: transparent;
			opacity: 0.1;
			left: 50%;
			top: 50%;
			width: 32px;
			height: 32px;
			transform: translate(-50%, -50%);
			border: 1px solid transparent;
			border-radius: 20px;
		}

		&:hover {
			&:after {
				background-color: $base-color;
			}
		}
	}

	textarea {
		height: 200px;
		border: solid 1px #ececec;
		resize: none;
		padding: 2rem 3rem;
		padding-right: 4.5rem;
		margin: 1rem 0 3rem 0;
		font-size: 1.8rem;
		font-family: $font;
		line-height: 1.44;
		color: $black-lighter;
		line-height: 26px;
		transition: all 0.3s ease-in-out;
		width: 100%;
		box-shadow: none !important;

		@media screen and (max-width: 550px) {
			padding: 2rem 2.6rem;
		}

		&:focus {
			outline: none !important;
			border: 1px solid $black-lighter;
			box-shadow: none;
		}
	}

	textarea::placeholder {
		color: $black-lighter;
		opacity: 0.3;
		font-size: 1.8rem;
		font-family: $font;
		line-height: 1.44;
	}

	@media screen and (max-width: 550px) {
		textarea {
			font-size: 16px;

			&::placeholder {
				font-size: 1.45rem;
			}
		}
	}
}

.Search_Flags {
	display: flex;
	flex-direction: column;
	margin-right: 1rem;

	div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		margin: 1rem 0;

		img {
			width: 34px;
			height: 34px;
			margin-right: 1.4rem;
			border: 2px solid white;
			border-radius: 50%;
		}
	}
}

.Search_FlagsTitle {
	font-size: 12px;
	letter-spacing: 0.2px;
	color: lighten($black, 70);
	text-transform: uppercase;

	@media screen and (max-width: 550px) {
		font-size: 9px;
	}
}

.Search_Flags_Selected {
	border: 2px solid $btn-primary !important;
	border-radius: 50%;
}

.Search_FlagsLanguage {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media screen and (max-width: 470px) {
		flex-direction: column;

		& > div {
			&:nth-child(1) {
				order: 2;
				margin-top: 2rem;
			}
			&:nth-child(2) {
				order: 1;
			}
		}
	}
}

.Search_LanguageSwitch {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	span {
		font-size: 12px;
		letter-spacing: 0.2px;
		color: #b3b3b3;
		text-transform: uppercase;
		margin-bottom: 1rem;

		@media screen and (max-width: 550px) {
			font-size: 9px;
		}
	}
}

.Search_LanguageContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 30px;
}
