.Layout {
}

.Layout_Content {
	flex-grow: 1;
	flex-shrink: 0;
	margin-right: auto;
	margin-left: auto;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	padding-top: 5rem;
	padding-bottom: 4rem;
	width: 100%;
}
