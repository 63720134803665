@import 'scss/variables';

.Footer {
	background: #f8f8f8;
	flex-shrink: 0;
	text-align: center;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;

	@media screen and (max-width: 550px) {
		font-size: 1.2rem;
	}

	p {
		color: #aaaaaa;
	}

	span {
		color: #aaaaaa;
		margin: 0 0.5rem;
	}

	a {
		cursor: pointer;
		color: #aaaaaa;
		text-decoration: none;
		transition: 0.2s color ease-in;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 1px;
			bottom: -1px;
			width: 0px;
			background-color: $base-color;
			transition: 0.2s width ease-in;
		}

		&:hover {
			color: $base-color;

			&:after {
				width: 100%;
			}
		}
	}
}
